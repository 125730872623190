import React from "react";
import {Link} from "gatsby";

const RecentPost = ({post}) => {
    return (
        <article className="recent-post-item-container">
            <a href={`/blog/${post.frontmatter.category.toLowerCase()}${post.fields.slug}`} aria-label={post.frontmatter.alt}>
                <img className="recent-post-item-image" src={post.frontmatter.image} alt={post.frontmatter.alt} aria-label="to recent post"/>
            </a>
            <div>
                <Link className="recent-posts-item-link" to={`/blog/${post.frontmatter.category.toLowerCase()}${post.fields.slug}`} aria-label="to recent post">
                    <h3 className="recent-posts-item">{post.frontmatter.title}</h3>
                </Link>
                <small className="recent-post-item-date">
                    posted {post.frontmatter.date}
                </small>
                <p className="recent-post-item-excerpt">{post.frontmatter.desc}</p>
            </div>
        </article>
    );
}

export default RecentPost;
