import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import "../styles/index.css";
import Seo from "../components/seo";
import Particles from "react-particles-js";
import BounceScrollDownArrow from "../components/bounce-scroll-down-arrow";
import Navbar from "../components/navbar";
import RecentPosts from "../components/recent-posts";

const Home = ({data}) => {
    const {title, description} = data.site.siteMetadata;

    return (
        <Layout>
            <Seo/>
            <Navbar/>
            <div className="index-page-container">
                <div className="index-page-title">{title}</div>
                <div className="index-page-description">{description}</div>
                <Particles
                    className="index-page-particles"
                    params={{
                        "particles": {
                            "number": {
                                "value": 130,
                                "density": {
                                    "enable": false
                                }
                            },
                            "size": {
                                "value": 3,
                                "random": true,
                                "anim": {
                                    "speed": 1,
                                    "size_min": 0.3
                                }
                            },
                            "line_linked": {
                                "enable": false
                            },
                            "move": {
                                "random": true,
                                "speed": 1,
                                "direction": "top",
                                "out_mode": "out"
                            }
                        },
                        "interactivity": {
                            "modes": {
                                "bubble": {
                                    "distance": 250,
                                    "duration": 2,
                                    "size": 0,
                                    "opacity": 0
                                },
                            }
                        }
                    }}/>
                <div className="index-page-explore">
                    EXPLORE POSTS
                </div>
                <BounceScrollDownArrow/>
            </div>
            <RecentPosts/>
        </Layout>
    )
}

export const query = graphql`
    query MetadataQuery {
        site {
        siteMetadata {
            title
            description
        }
    }
}`;

export default Home;
