import React from 'react';
import "../styles/bouncing-arrow.css";
import {FaArrowDown} from 'react-icons/fa';

const BounceScrollDownArrow = () => {
    return (
        <div className="arrow bounce">
            <FaArrowDown size={30}/>
        </div>
    )
}

export default BounceScrollDownArrow;
