import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import "../styles/recent-posts.css";
import RecentPost from "./recent-post";

const RecentPosts = () => {
    const data = useStaticQuery(query);

    return (
            <div className="recent-posts-container">
                <div className="recent-posts-items">
                    {data.recentPosts.posts.map(post => (
                       <RecentPost key={post.id} post={post} />
                    ))}
                </div>
            </div>
    )
}

export const query = graphql`
    query getRecentPosts {
        recentPosts: allMarkdownRemark(
            limit: 5
            sort: { fields: [frontmatter___date], order: DESC }
            ) {
                posts: nodes {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(fromNow: true)
                        category
                        desc
                        image
                        alt
                    }
                    id
                    excerpt
                }
            }
        }
    `;

export default RecentPosts;
